.confirmationNotice {
    .banner {
        background-color: #d4edda;
        border-color: #c3e6cb;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;

        .icon {
            width: 25%;
            margin-bottom: 1rem;
        }

        .title {
            font-weight: 700;
            font-size: 2.25rem;
        }
    }

    .buttons {
        margin-top: 1.5rem;
        display: flex;
        justify-content: center;
    }
}
