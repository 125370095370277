.errorMessageWithIcon {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
        width: 25%;
        margin-bottom: 1rem;
    }

    .title {
        font-weight: 700;
        margin-bottom: 1rem;
        font-size: 2.25rem;
    }

    p {
        margin-bottom: 1rem;
        text-align: center;
    }
}