.TimerButton {
    color: var(--button-text-color);
    background: var(--button-background-color);
    border: 1px solid var(--button-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.timer {
        background: var(--button-background-inactive-color);
        background-image: linear-gradient(
            180deg,
            var(--button-background-color) 0%,
            var(--button-background-color) 100%
        );
        background-repeat: no-repeat;
    }

    .spinner {
        border: 4px solid #f3f3f3;
        border-top: 4px solid #3498db !important;
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        animation: spin 0.6s linear infinite;
        margin-right: 1rem;
        position: absolute;
        left: 1rem;
    }

    .text {
        flex-grow: 1;
        text-align: center;
    }
}

.GrayTimerButton {
    color: var(--gray-button-text-color);
    background: var(--gray-button-background-color);
    border: 1px solid var(--gray-button-background-color);

    &.timer {
        background: var(--gray-button-background-inactive-color);
        background-image: linear-gradient(
                180deg,
                var(--gray-button-background-color) 0%,
                var(--gray-button-background-color) 100%
        );
        background-repeat: no-repeat;
        font-size: 24px;
    }
}

@keyframes timer {
    from {
        background-size: 0 100%;
    }
    to {
        background-size: 100% 100%;
    }
}
