.startOrStopShift {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 1.5rem;

    .time {
        flex-grow: 1;
        margin-right: 3rem;
        text-align: right;

        .larger {
            margin-top: 5px;
            font-size: 2.25rem;
        }
    }
    .action {
        flex-grow: 1;

        button {
            width: 100%;
        }
    }
}
